<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col sm="2" class="mb-2">
						<div class="image-input">
							<b-img :src="getUrlOfFile(models.image_profile)" thumbnail class="mb-2"/>
							<label for="file-input">
								<input 
									id="file-input" 
									type="file" 
									accept="image/png, image/gif, image/jpeg, image/jpg"
									v-on:change="AddPhoto" hidden/>
								<i class="fa fa-plus"></i> Upload
							</label>
						</div>
					</b-col>
					<b-col sm="10" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('first name')}" label="First Name">
							<b-form-input v-model="models.first_name" v-validate="'required'" data-vv-name="first name">
							</b-form-input>
							<div v-if="errors.has('first name')">
								<p class="text-danger">{{ errors.first('first name') }}</p>
							</div>
						</b-form-group>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('last name')}" label="Last Name">
							<b-form-input v-model="models.last_name" v-validate="'required'" data-vv-name="last name">
							</b-form-input>
							<div v-if="errors.has('last name')">
								<p class="text-danger">{{ errors.first('last name') }}</p>
							</div>
						</b-form-group>
						<b-form-group v-if="is_contributor" v-bind:class="{ 'is-invalid': errors.has('Pen-name')}" label="Pen-name">
							<b-form-input v-model="pena_name" v-validate="'required'" data-vv-name="Pen-name">
							</b-form-input>
							<div v-if="errors.has('Pen-name')">
								<p class="text-danger">{{ errors.first('Pen-name') }}</p>
							</div>
						</b-form-group>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('email')}" label="Email">
							<b-form-input type="email" v-model="models.email" v-validate="'required|email'"
								data-vv-name="email"></b-form-input>
							<div v-if="errors.has('email')">
								<p class="text-danger">{{ errors.first('email') }}</p>
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="submit" variant="primary" class="btn-min-width float-left">
						Save
					</b-button>
				</template>
				
			</b-card>
		</div>
	</form>
</template>
<script>
	import store from '@/store/store.js';
	import api from '@/utils/api.js';
	import { getUrlOfFile } from '@/utils/helpers.js';
	import ResponseAlert from '@/components/response_alert';
	export default {
		name: 'ChangeProfile',
		components: {
			ResponseAlert,
		},
		data() {
			return {
				models: {
					first_name: "",
					last_name: "",
					email: "",
					image_profile: "",
				},
				pena_name: "",
				is_contributor: false,
				vars: {
					FormData: new FormData()
				},
				getUrlOfFile
			}
		},
		created() {
			const _ = this;
			_.getUser();
		},
		methods: {
			getUser() {
				const _ = this;
				_.$axios.get(api.profile)
					.then(res => {
						const { data } = res.data;
						_.models.first_name = data.first_name;
						_.models.last_name = data.last_name;
						_.models.email = data.email;
						_.models.image_profile = data.image_profile;
						if(data.role.id === 1) {
							this.is_contributor = true
							this.pena_name = data.pena_name
						}
					})
					.catch(() => store.dispatch('logout'));
			},
			AddPhoto(event) {
				const _ = this;
				_.models.image_profile = event.target.files[0];
			},
			save() {
				const _ = this;
				_.vars.FormData = new FormData();
				for (const i in _.models) {
					_.vars.FormData.append(i, _.models[i]);
				}
				if(this.is_contributor) {
					_.vars.FormData.append('pena_name', this.pena_name)
				}
				_.$validator.validate().then(valid => {
					if (valid) {
						_.$axios.put(api.profile, _.vars.FormData)
							.then(res => {
								_.$refs.response.setResponse(res.data.message, 'success');
								location.reload();
							}).catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			}
		}
	}
</script>